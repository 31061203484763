import React from "react";
import "./App.css";

const SocialLink = ({ href, src, alt }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className="social-link">
    <img src={src} alt={alt} className="social-icon" loading="lazy" />
  </a>
);

const App = () => {
  const socialLinks = [
    {
      href: "https://medium.com/@XAYA",
      src: "https://cdn.prod.website-files.com/628775340eb1a87e1ba774e8/62986a7ba202e7a16ca3fbd3_medium.png",
      alt: "Medium",
    },
    {
      href: "https://x.com/XAYA_tech/status/850072100413087744",
      src: "https://cdn.prod.website-files.com/628775340eb1a87e1ba774e8/62986a9ec1fdda01f20c8e0d_twitter.png",
      alt: "Twitter",
    },
    {
      href: "https://www.facebook.com/XAYAtech/",
      src: "https://cdn.prod.website-files.com/628775340eb1a87e1ba774e8/62986b9672bfc95c90de6ce8_facebook%20(1).png",
      alt: "Facebook",
    },
    {
      href: "https://t.me/xaya_en",
      src: "https://cdn.prod.website-files.com/628775340eb1a87e1ba774e8/62986becb942ea04a1806c62_telegram.png",
      alt: "Telegram",
    },
    {
      href: "https://bitcointalk.org/index.php?topic=1784048",
      src: "https://cdn.prod.website-files.com/628775340eb1a87e1ba774e8/6298699dc1fdda473f0c8840_bitcoin.png",
      alt: "BitcoinTalk",
    },
  ];

  return (
    <div className="app-container">
      <header className="header">
        <img
          src="https://s2.coinmarketcap.com/static/img/coins/200x200/130.png"
          alt="Huntercoin Logo"
          className="logo"
        />
        <nav className="navigation">
          <ul>
            {Object.entries({
              "Information": "https://github.com/chronokings/huntercoin/tree/master/src",
            }).map(([label, url]) => (
              <li key={label}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      <section className="banner">
        <img
          src="https://cdn.prod.website-files.com/628775340eb1a87e1ba774e8/6681ad4242278743f1150190_huc.png"
          alt="Huntercoin Banner"
          className="banner-image"
          loading="lazy"
          srcSet="https://cdn.prod.website-files.com/628775340eb1a87e1ba774e8/6681ad4242278743f1150190_huc-p-500.png 500w, https://cdn.prod.website-files.com/628775340eb1a87e1ba774e8/6681ad4242278743f1150190_huc.png 555w"
          sizes="(max-width: 479px) 100vw, 472px"
        />
      </section>

      <main className="main">

        <section className="content">
          <h1>Huntercoin</h1>
          <p className="description">
            Huntercoin, a pioneering Open Source Cryptocurrency launched in February 2014, has embarked on a new chapter by migrating to the Solana blockchain. Originally a groundbreaking experiment similar to Bitcoin, Huntercoin distinguished itself as a "play2earn" innovator long before the term became mainstream. Unlike Bitcoin, where coins are mined through computational power, ~80% of Huntercoin's coins were obtainable by players collecting them within a virtual universe embedded in the blockchain—a concept driven by a simple yet engaging game featuring Player vs Player combat to compete for resources. This unique approach, dubbed Human (or AI) mining, set Huntercoin apart as a trailblazer in blockchain gaming.
          </p>
          <p className="description">
            Though it does not run on the XAYA platform, Huntercoin shares its technological roots and development team with XAYA, which evolved from the same innovative spirit. Released as a live test to explore how blockchain could sustain fully functional game worlds, Huntercoin proved its concept brilliantly, laying the groundwork for future advancements in decentralized gaming.
          </p>
          <p className="description">
            Now, in 2025, Huntercoin’s legacy finds new life on Solana, a blockchain renowned for its speed, scalability, and low-cost transactions. This migration honors Huntercoin's history as a cryptographically secure, decentralized, and innovative project while harnessing Solana’s cutting-edge infrastructure to enhance its virtual universe. Players can once again dive into the iconic game world, collecting coins and battling for resources, all powered by Solana’s high-performance network. Join us as Huntercoin bridges its storied past with a dynamic future—ready to redefine "play2earn" for a new era.
          </p>
        </section>
        <h2>CA: G38LivFRZnyj4BB396P3gYBYHigYSPrinxMU6inpump</h2>
      </main>

      <footer className="footer">
        <div className="social-links">
          {socialLinks.map((link) => (
            <SocialLink key={link.href} {...link} />
          ))}
        </div>
      </footer>
    </div>
  );
};

export default App;